import React from 'react';

function PricingPage() {
  return (
      <>
          <script async src="https://js.stripe.com/v3/pricing-table.js"></script>
          <stripe-pricing-table pricing-table-id="prctbl_1OonQSDISBjxczlLQMUikAVL"
                                publishable-key="pk_live_51Oie8yDISBjxczlLynf4WCplRHs1mocwjc48W1Vpe8VPkaJLdq4ExfwwVjZa82zaWhn2XWMqD7yQylilPmjktMpU00OpjB3Swf">
          </stripe-pricing-table></>
  );
}

export default PricingPage;
